$blue: #1c75bc;
$green: #87c154;

$red: #D84848;
$orange: #E09149;
$yellow: #E1CD63;

$blue-li-20: #4991c9;
$blue-li-50: #9ecff7;
$green-li-50: #c3e0aa;

.green {
  color: $green;
}

.App {
  padding-top: 30px;
  margin: 0px 60px;
  background-color: white;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;

  > a {
    display: flex;
  }

  img {
    height: 2.5em;
  }
}

h1 {
  font-size: 28px;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-around;

  > a {
    margin: 10px;
    font-size: 20px;
  }
}

a {
  text-decoration: none;
  color: $blue;
}

a:hover {
  font-weight: bold;
}

figure {
  // border: 2px solid black;
  width: 200px;
  padding: 20px;

  border-radius: 10px;
  border: 2px solid lightgray;

  img {
    width: 200px;
  }

  figcaption {
    margin-top: 1em;
    text-align: center;
    font-weight: bold;
    color: rgb(73, 73, 73);
  }

  .round-frame {
    overflow: hidden;
    height: 200px;
    border-radius: 100px;
    img {
      margin: 0px;
    }
  }

  &.aside-right {
    margin-inline-end: 0px;
    float: right;
  }

  &.aside-left {
    margin-inline-start: 0px;
    float: left;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.bio {
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;

  margin: 0px -60px;

  div {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
  }

  .shiny-button {
    width: fit-content;
    margin-right: 2em;
  }

  &> img {
    object-fit: cover;
    width: 100%;

    max-height: 60vh;
    min-width: 100vw;
  }

}

.slide {
  text-align: center;
  font-size: 20px;

  .attribution {
    text-align: right;
    font-style: italic;
    color: $blue;
    &:before {
      content: "—"
    }
  }
}

section {
  margin: 0px;
  padding: 3em 0em;
}

.slick-slider {
  margin-bottom: 3em;
}

.quizlet {

  margin: auto;
  padding: 1em;

  display: flex;
  justify-content: center;

  position: relative;

  .back {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .grade-level-menu {
    display: flex;
    flex-direction: column;

    border: 1px solid lightgray;
    border-radius: .5em;
    padding: 0em 1em;

    margin-right: 1em;

    button {
      background-color: transparent;
      border: none;
      font-size: 16px;
      font-weight: bold;
      color: $blue;

      &:hover {
        color: black;
      }
    }
  }

  .problem {
    margin: .5em;
    max-width: 800px;
    flex-grow: 1;
  }

  .result {
    max-width: 800px;
  }

  .answers {
    margin-top: 1em;


    button {
      margin-top: .5em;
      width: 100%;
      height: 3em;
      background-color: white;
      border: 1px solid $blue-li-50;
      border-radius: .25em;

      font-weight: bold;

      &:hover {
        background-color: $blue-li-50;
        color: white;
      }
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  padding-bottom: 1em;

  // position: absolute;
  // bottom: 0px;
  // left: 0px;
  // padding: 30px 60px;
  // box-sizing: border-box;

  width: 100%;

  .footer-contact a {
    margin-left: 1em;
  }
};

.splash-text {
  font-size: 20px;
  line-height: 1.5em;
}

.shiny-button {
  display: inline-block;
  border: none;
  padding: .5em;

  border: 2px solid $blue;
  background-color: $blue;
  color: white;

  border-radius: .25em;
  font-weight: bold;
  font-size: 10pt;
  white-space: nowrap;
}

.services {
  display: grid;
  grid-template-columns: auto 275px;

  aside {
    border: 2px solid lightgray;
    border-radius: .5em;
    margin-left: 1em;
    padding: 1em;
    padding-top: 0em;
    height: fit-content;
    width: 250px;

    position: sticky;
    top: 1em;

    ul {
      list-style: none;
      padding-inline-start: .5em;

      p {
        padding-inline-start: .5em;
      }
    }

    h4 {
      margin: 0px;
    }

  }
}

.service {
  position: relative;
  margin: auto;
  margin-bottom: 1em;
  max-width: 800px;

  border: 2px solid $blue-li-50;
  border-radius: .5em;

  padding: 1em;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .price {
    color: $blue;
    font-weight: bold;
  }

  .icon-window {
    margin-right: 1em;
  }

  h3 {
    margin: 0px;
  }

}

main {
  margin-top: 2em;
  margin-bottom: 1em;
}

.contact {
  display: flex;
  margin-bottom: 2em;
}

.carousel {
  width: 80%;
  margin: auto;
}

.info-card {
  display: flex;
  flex-direction: column;
  // text-align: center;

  line-height: 1.5em;

  padding: 1em 3em;
  margin: 1em;

  border: 1px solid lightgray;
  border-radius: .5em;

  .card-head {
    .icon-frame {
      display: none;
    }
    h3 {
      float: right;
      font-size: 20pt;
      text-align: right;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .icon-frame {
    height: 70px;
    width: 70px;
    border-radius: 35px;
    overflow: hidden;
  }

  h3 {
    margin: .25em;
  }
}

.card-container {
  display: grid;
  grid-template-columns: auto auto;
}

//for wide screen only
.shinies {
    display: flex;
    .slick-slider {
      width: 45%;
      padding: 2em;
    }
  }

@media only screen and (max-width: 800px) {
  .card-container {
    grid-template-columns: auto;
    .icon-frame {
      display: none;
    }
  }

  .shinies {
    flex-direction: column;
    .slick-slider {
      width: 100%;
    }
  }

  .bio {
    padding-top: none;
    figure {
      display: none;
    }
  }

  .quizlet {
    flex-direction: column;

    .grade-level-menu {
      flex-direction: row;
      margin: 0px;
      button {
        font-size: 10pt;
        margin: 1em;
      }
    }
  }

  .services {
    grid-template-columns: auto;
    aside {
      margin-left: 0px;
      width: auto;
    }
  }

}

@media only screen and (max-width: 600px) {
  header {
    flex-direction: column;
  }

  .App {
    margin: 0px 20px;
  }

  .bio {
    width: 80%;
    margin: auto;

    img {
      display: none;
    }
  }

  .info-card {
    padding: 1em;
  }
}